:root {
  /* secondary color*/
  --primary-color: #274ecb;

  --muted-primary: #021e4c;

  /* secondary color*/
  --secondary-color: #f5bf13;

  --subheading-color: #808080;
  /* ligher shade of blue */
  --light-blue-color: #f6f8fe;

  /* darker shade of blue */
  --dark-blue-color: #aebaf4;

  /* green color used for success */
  --dark-green-color: #008000;

  /* border color */
  --border-color: #dbdcdf;

  /* form background color */
  --form-color: #f5f5f5;

  /* used for edit button color */
  --blue-color: #3d5ccb;

  /* used for delete button color */
  --red-color: #ff4d4f;

  /* used for title color */
  --midnight-blue: #27303c;

  --white-color: #ffffff;

  --black-color: #000000;
  --subheading: #e6e6e6;
  --titleColor: #858585;
  --menuTitleColor: #333333;
  --selectPlaceholder: #908c89;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  font-family: 'Noto Sans', 'san-serif';
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace; */
}

.floating-label {
  position: absolute;
  top: 0;
  z-index: 10;
  background: var(--white-color);
  margin-left: 16px;
  margin-top: -12px;
  padding: 0px 4px;
  font-weight: 600;
}

.bg-badge {
  background: var(--subheading);
  color: var(--menuTitleColor);
}

.card-style {
  padding: 0px !important;
  background-color: var(--subheading);
  border-radius: 50px;
}

.ant-btn.ant-btn-lg {
  border-radius: 24px !important;
}

.ant-card.card-style .ant-card-body {
  padding: 8px 24px !important;
  border-radius: 0 0 8px 8px;
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  overflow: visible !important;
}

.ant-menu-submenu {
  padding: 0px !important;
}

.delete-icon-size {
  font-size: 20px !important;
}

.cancel-button.ant-btn.ant-btn-lg {
  border-color: var(--primary-color);
}

.isActive-button-parent .ant-select-selector {
  padding: 12px 12px !important;
  border-radius: 20px !important;
}

.isActive-button-parent .ant-select-arrow {
  margin-top: -5px !important;
}

.ant-table-thead>tr th {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  font-size: 17px;
  /* font-family:Arial ; */
}

/* FORM.IO CSS */
/* floating label css start */
.form-group {
  position: relative;
  margin: 30px 0px;
}

.control-label {
  position: absolute;
  top: -12px;
  margin-left: 16px;
  background: white;
  padding: 0px 4px;
  z-index: 10;
}

.form-group .form-control {
  padding-top: 12px;
  padding-bottom: 12px;
}

.col-form-label {
  position: absolute;
  top: -10px;
  margin-left: 16px;
  background: white;
  padding: 0px 2px;
  z-index: 10;
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.form-check-label {
  margin-top: 12px;
}

.drag-copy {
  width: 100%;
  padding: 8px 0px 8px 8px !important;
  margin-bottom: 4px !important;
  font-size: 14px !important;
}

.ace_content {
  margin-top: 20px !important;
}

.ace_gutter {
  margin-top: 20px !important;
}

.form-builder-panel button {
  width: 100%;
}

.card.form-builder-panel {
  margin-bottom: 15px;
}

.builder-group-button {
  /* background-color: var(--primary-color) !important; */
  /* color: var(--white-color) !important; */
  font-weight: 500 !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.drag-and-drop-alert.no-drag {
  /* margin: 20px 0px; */
  border-radius: 8px;
}

.form-control.builder-sidebar_search {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 30px;
}

.formio.builder.row.formbuilder {
  margin: 30px 0px;
}

#save-buttons .pull-right {
  float: left !important;
}

#save-buttons .form-group {
  margin: 40px 0px 0px 0px;
}

.navbar {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.navbar-nav {
  gap: 10px;
}

.navbar-nav a:last-child {
  background-color: var(--primary-color);
  border-radius: 5px;
  color: var(--white-color);
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.navbar-nav a:last-child:hover {
  color: var(--white-color);
  background: var(--muted-primary);
}

.nav-item .btn {
  background-color: var(--primary-color);
  border-radius: 5px;
  color: var(--white-color);
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.nav-item .btn:hover {
  color: var(--white-color);
  background: var(--muted-primary);
}

.preview-panel .card-header {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.table.datagrid-table.table-bordered {
  border: 1px solid var(--midnight-blue) !important;
}

.table.datagrid-table .form-group {
  margin: unset !important;
}

.formio-dialog-close.float-right.btn-sm {
  background-color: var(--red-color) !important;
  color: var(--white-color) !important;
  border-radius: 25px;
  margin-top: 5px;
  margin-right: 5px;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-close:before {
  background-color: var(--red-color) !important;
  color: var(--white-color) !important;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-close:before {
  border-radius: 25px;
}

.login-form-card {
  max-width: 50%;
  margin: 0 auto;
}

.login-form-card-title {
  margin: 0 auto;
  font-size: 36px;
  font-weight: 600;
  color: var(--primary-color) !important;
}

.login-form-card button {
  background: var(--primary-color);
}

.login-form-card .formio-component-button {
  text-align: center;
  margin: 30px 0px 0px 0px !important;
}

.login-form-card .has-error {
  text-align: center;
  color: var(--red-color);
  margin-top: 16px !important;
}

.btn-primary {
  background: var(--primary-color) !important;
}

/* edit form page divider css start */
.container>div>.row {
  border-bottom: 3px solid #dee2e6;
}

.container .row.formbuilder>.formarea {
  border: 3px dashed #dee2e6;
  border-radius: 3px;
}

/* edit form page divider css end */