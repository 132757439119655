.form-manger-table .ant-table-thead>tr th {
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important;
    font-size: 16px !important;
    border-inline-end: 1px solid #f0f0f0;
}

.form-manger-table .ant-table-tbody>tr>td {
    transition: 0.2s;
    border-color: 0.2s;
    border-bottom: 1px solid #f0f0f0 !important;
    border-inline-end: 1px solid #f0f0f0 !important;
    border-inline-start: 1px solid #f0f0f0 !important;
}

.form-manger-table .ant-spin-container {
    margin-top: 20px !important;
}

.form-manager-create-button {
    border-radius: 20px !important;
    color: var(--primary-color);
    border-color: var(--primary-color);
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    font-size: 18px;
    height: 40px;
    padding: 6px 15px;
}

.form-manager-create-button:hover {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.form-manager-edit-icon {
    border-radius: 50px;
    padding: 10px 20px;
    background-color: var(--white-color);
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
    font-size: 16px;
    border: 1px solid;
    width: 40px !important;
    height: 40px !important;
}

.form-manager-edit-button {
    font-size: 18px;
    background-color: var(--primary-color);
    padding: 6px 15px;
    height: 40px;
}

.form-manager-edit-button:hover {
    background-color: var(--primary-color) !important;
}

.custom-breadcrumb {
    margin-top: 10px;
    justify-content: space-between;
}

.form-manager-edit-icon:hover {
    background-color: var(--white-color);
}

.form-manger-table th.ant-table-cell::before {
    width: 0px !important;
}

.form-manager-delete-button {
    border-radius: 50px;
    padding: 10px 20px;
    background-color: var(--white-color);
    border-color: var(--red-color);
    color: var(--red-color);
    font-size: 16px;
    border: 1px solid;
    width: 40px !important;
    height: 40px !important;
}



.form-manager-delete-button:hover {
    background-color: var(--white-color);
    border-color: var(--red-color) !important;
    color: var(--red-color) !important;
}

.form-manger-table .ant-pagination .ant-pagination-item-active {
    border-color: var(--primary-color) !important;
}