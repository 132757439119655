/* Custom Breadcrumb */
.custom-breadcrumb {
    background-color: #e6e6e6;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 8px 22px !important;
    border-radius: 50px;
    border: 1px solid #f0f0f0;
}

.custom-breadcrumb-title {
    font-size: 22px !important;
    color: #333333 !important;
    font-weight: 600 !important;
    font-family: 'Noto Sans' !important;
    align-items: center;
    margin-inline-start: 8px;
}

/* Custom Icon */
.custom-breadcrumb-icon {
    margin-right: 8px;
    font-size: 30px;
}