:root {
  /* secondary color*/
  --primary-color: #274ecb;

  --muted-primary: #021e4c;

  /* secondary color*/
  --secondary-color: #f5bf13;

  --subheading-color: #808080;
  /* ligher shade of blue */
  --light-blue-color: #f6f8fe;

  /* darker shade of blue */
  --dark-blue-color: #aebaf4;

  /* green color used for success */
  --dark-green-color: #008000;

  /* border color */
  --border-color: #dbdcdf;

  /* form background color */
  --form-color: #f5f5f5;

  /* used for edit button color */
  --blue-color: #3d5ccb;

  /* used for delete button color */
  --red-color: #ff4d4f;

  /* used for title color */
  --midnight-blue: #27303c;

  --white-color: #ffffff;

  --black-color: #000000;
  --subheading: #e6e6e6;
  --titleColor: #858585;
  --menuTitleColor: #333333;
  --selectPlaceholder: #908c89;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}