@import "~bootstrap/dist/css/bootstrap.css";
@import "~@formio/js/dist/formio.full.min.css";

.nav-link a {
  color: inherit;
  text-decoration: none;
}

.formio-brand {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formio-brand > * {
  margin-right: 5px;
}

.navbar-brand a {
  color: inherit;
  text-decoration: none;
}

.react-logo {
  color: #0a7ea3;
}

div.spacer {
  margin: 0.5rem 0;
  line-height: 0;
}

.container {
  max-width: 1400px;
}

.layout-header {
  display: 'flex';
  align-items: center;
  height: 70px;
  padding: 0 50px;
  /* color: rgba(0, 0, 0, 0.88); */
  line-height: 64px;
  background: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.layout-header-links-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.layout-header-links-wrapper a:last-child {
  color: var(--white-color);
}

.layout-header-links-wrapper a:last-child:hover {
  background-color: var(--primary-color);
}

.nav-link-item {
  padding: 5px;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
}

.nav-link-item:hover {
  color: black;
}

.log-in-out-button {
  background-color: var(--primary-color);
  border-radius: 5px !important;
}

.log-in-out-button:hover {
  background-color: var(--muted-primary) !important;
}

.log-in-out-button a:hover {
  background-color: var(--muted-primary) !important;
}